import React from "react"
import styled from "styled-components"
import colors from "../../styles/colors"
import fonts from "../../styles/fonts"
import breakpoints from "../../styles/breakpoints"
import { GatsbyImage } from "gatsby-plugin-image"
import Text from "src/components/Blocks/Text"
import CtaButton from "src/components/Core/CtaButton"

const BlockContainer = styled.div`
  padding-top: 5vw;
  padding-right: 5vw;
  padding-bottom: 5vw;
  padding-left: 5vw;
`

const Background = styled.div`
  background-color: ${(props) =>
    props.bg === "Pink"
      ? colors.pink
      : props.bg === "Blue"
      ? colors.royalBlue
      : colors.lightGrey};
  padding: 6.6vmax 6vw;
`

const Content = styled.div`
  @media (min-width: ${breakpoints.lg}) {
    width: 75%;
    margin: auto;
  }
`

const EyebrowIcons = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 17px;
`

const Icon = styled.div`
  margin: 0 6px;
`

const BlockHeading = styled.h2`
  font-size: calc((4 - 1) * 1.2vh + 1rem);
  line-height: 1;
  color: ${colors.royalBlue};
  text-align: center;
  padding: 17px 0;

  @media (min-width: ${breakpoints.md}) {
    font-size: calc((4 - 1) * 1.2vw + 1rem);
    margin-bottom: 68px;
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 34px;
  row-gap: 90px;

  @media(min-width: ${breakpoints.md}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media(min-width: ${breakpoints.lg}) {
    column-gap: 60px;
  }
`

const TextBlock = styled.div`
  text-align: center;
  color: ${colors.white};

  h3 {
    text-transform: uppercase;
    ${fonts.workSansBold}
    font-size: calc((1.5 - 1) * 1.2vw + 1rem);
    line-height: 1.176;
    padding: 17px;
  }
  p {
    font-size: calc((1 - 1) * 1.2vw + 1rem);
    line-height: 1.6;
    padding: 0 17px;
  }
`

const ButtonContainer = styled.div`
  margin-top: 83px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Order = ({ block }) => {

  const { backgroundColor, eyebrowIcons, blockHeading, textBlocks, ctaLabel, ctaUrl } = block
  return (
    <BlockContainer>
      <Background bg={backgroundColor}>
        <Content>
          {eyebrowIcons && (
            <EyebrowIcons>
              {eyebrowIcons.map((icon, index) => {
                return (
                  <Icon key={index}>
                    <GatsbyImage
                      image={icon.gatsbyImageData}
                      alt={icon.description}
                    />
                  </Icon>
                )
              })}
            </EyebrowIcons>
          )}
          {blockHeading && <BlockHeading>{blockHeading}</BlockHeading>}
          <Grid>
            {textBlocks &&
              textBlocks.map((block, index) => {
                const { heading, content } = block
                return (
                  <TextBlock key={index}>
                    {heading ? <h3>{heading}</h3> : null}
                    {content ? <Text content={content} /> : null}
                  </TextBlock>
                )
              })}
          </Grid>
          {ctaUrl && ctaLabel && (
            <ButtonContainer>
              <CtaButton url={ctaUrl} label={ctaLabel} />
            </ButtonContainer>
          )}
        </Content>
      </Background>
    </BlockContainer>
  )
}

export default Order
