import React from "react"
import SimpleBlock from "../Blocks/SimpleBlock"
import PageBlockGroup from "../Blocks/PageBlockGroup"
import TextImageBlock from "../Blocks/TextImageBlock"
import HowItWorksBlock from "../Blocks/HowItWorksBlock"
import CollectBlock from "../Blocks/CollectBlock"
import TextVideoBlock from "../Blocks/TextVideoBlock"
import StickyCarouselBlock from "../Blocks/StickyCarouselBlock"
import HowItWorksInfoBlock from "../Blocks/HowItWorksInfoBlock"
import PartnersBlock from "../Blocks/PartnersBlock"
import FAQBlock from "../Blocks/FAQBlock"
import Order from "./Order"
import DeliveryZone from "src/components/Index/DeliveryZone"
import ProductLandingPageBlock from "src/components/Index/ProductLandingPageBlock"



const Blocks = (props) => {
  const blocks = props.blocks

  return (
    <>
      {blocks && blocks.map((block, index) => {
        const { __typename: type } = block

        if (type === "ContentfulTextVideoBlock") {
          return <TextVideoBlock {...block}></TextVideoBlock>
        }
        if (type === "ContentfulCollectBlock") {
          return <CollectBlock {...block} key={index} />
        }

        if (type === "ContentfulSimpleBlock") {
          return <SimpleBlock {...block} key={index} />
        }

        if (type === "ContentfulTextImageBlock") {
          return <TextImageBlock {...block} key={index} />
        }

        if (type === "ContentfulPageBlock") {
          return <Order key={index} block={block}  />
        }

        if (type === "ContentfulPageBlockGroup") {
          return <PageBlockGroup {...block} key={index} />
        }

        if (type === "ContentfulHowItWorksBlock") {
          return <HowItWorksBlock {...block} key={index} />
        }

        if (type === "ContentfulStickyCarouselBlock") {
          return <StickyCarouselBlock {...block} key={index} />
        }
        
        if (type === "ContentfulHowItWorksInfoBlock") {
          return <HowItWorksInfoBlock {...block} key={index} />
        }

        if (type === "ContentfulPartnersBlock") {
          return <PartnersBlock {...block} key={index} />
        }

        if (type === "ContentfulZipCodeBlock")
          return <><DeliveryZone backgroundColor={"royalBlue"} {...block}></DeliveryZone></>
     

      })}

      

    </>
  )
}

export default Blocks
