import React from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Core/Seo"
import { graphql } from "gatsby"
import Hero from "../components/Blocks/Hero"
import Blocks from "../components/HowItWorks/Blocks"



const HowItWorksPage = (props) => {
  const { location, data } = props
  const path = location.pathname

  const page = data.contentfulPage

  let sharingImage = false
  if (page.socialMediaImage) {
    sharingImage = page.socialMediaImage.file.url
  }

  let videoURL;
  if (page.hero && page.hero.video)
  {
    try{
      videoURL = page.hero.file.url
    } catch(e)
    {
      console.log(e);
    }

  }
  return (
    <Layout>
      <Seo
        title={page.metaTitle}
        description={page.metaDescription}
        fullTitle={true}
        path={path}
        image={sharingImage}
      />
      <Hero heroData={page.hero} lg/>
      <Blocks blocks={page.blocks} />
    </Layout>
  )
}

export default HowItWorksPage

export const pageQuery = graphql`
  query HowItWorksQuery {
    contentfulPage(slug: { eq: "how-it-works" }) {
      blocks {
        __typename
        ... on ContentfulSimpleBlock {
          image {
            gatsbyImageData
            description
          }
          content {
            raw
          }
        }
        ... on ContentfulPageBlockGroup {
          title
          backgroundColor
          eyebrowIcons {
            gatsbyImageData(width: 30)
            description
          }
          ctaLabel
          ctaLink
          blocks {
            ... on ContentfulTextImageBlock {
              heading
              headingHighlight
              media {
                gatsbyImageData
                description
              }
              content {
                raw
              }
            }
          }
        }
        ... on ContentfulTextImageBlock {
          heading
          headingHighlight
          backgroundColor
          media {
            gatsbyImageData
            description
          }
          content {
            raw
            references {
              ... on ContentfulAsset {
                __typename
                contentful_id
                gatsbyImageData(width: 32)
              }
            }
          }
          ctaLabel
          ctaLink
          imagePosition
        }
        ... on ContentfulPageBlock {
          backgroundColor
          blockHeading
          ctaLabel
          ctaUrl
          eyebrowIcons {
            gatsbyImageData(width: 30)
            description
          }
          image {
            gatsbyImageData
            description
          }
          textBlocks {
            content {
              raw
            }
            heading
          }
          blockFooterText {
            raw
          }
        }
        ... on ContentfulHowItWorksBlock {
          id
          title
          backgroundColor
          blockFooter {
            raw
          }
          eyebrowIcons {
            gatsbyImageData(width: 32)
            description
          }
          firstSection {
            __typename
            ... on ContentfulSimpleBlock {
              image {
                gatsbyImageData
                description
              }
              content {
                raw
              }
            }
          }
          secondSection {
            __typename
            ... on ContentfulSimpleBlock {
              image {
                gatsbyImageData
                description
              }
              content {
                raw
              }
            }
          }
        }
      }
      hero {
        ctaLabel
        ctaUrl
        image {
          description
          gatsbyImageData
        }
        video {
          file {
            url
          }
        }
        title
        content {
          raw
        }
      }
      metaDescription
      metaTitle
      title
      socialMediaImage {
        file {
          url
        }
      }
    }
  }
`
